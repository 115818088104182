import { Classes } from "@blueprintjs/core";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components";

import { numberWithCommas } from "../../../util/number.js";
import { Heading } from "../../Heading";

export interface HomeTabTitleProps {
  leftAction?: React.ReactNode;
  rightAction?: React.ReactNode;
  helpText?: React.ReactNode;
  icon?: React.ReactNode;
  iconColor?: string;
  text: string;
  totalCount?: number | null;
  showTotalCount?: boolean;
  small?: boolean;
}

export const HomeTabTitle2: React.FunctionComponent<HomeTabTitleProps> = ({
  helpText,
  icon,
  iconColor,
  leftAction = null,
  rightAction = null,
  showTotalCount = false,
  small = false,
  text,
  totalCount,
}) => {
  return (
    <>
      <HeaderWrapper $small={small} as="header">
        <HeaderContent>
          <div>
            <FlexDiv>
              {icon && <IconWrapper $color={iconColor}>{icon}</IconWrapper>}
              <Heading renderAs={small ? "h4" : "h3"}>{text}</Heading>
              {leftAction}
            </FlexDiv>
            {showTotalCount && (
              <Total>
                {numberWithCommas(totalCount != null ? totalCount : 0)} item
                {totalCount === 1 ? "" : "s"}
              </Total>
            )}
            {helpText != null && <HelpText>{helpText}</HelpText>}
          </div>
          <RightActionWrapper>{rightAction}</RightActionWrapper>
        </HeaderContent>
      </HeaderWrapper>
    </>
  );
};

const HeaderWrapper = styled.div<{ $small: boolean }>`
  display: flex;
  align-items: center;

  margin-bottom: ${({ $small }) => !$small && "6px"};
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`;

const HeaderContent = styled(FlexDiv)`
  align-items: flex-end;

  @media (max-width: ${({ theme }) => `${theme.mediaQuery.EXTRA_SMALL}px`}) {
    display: block;
  }
`;

const RightActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  flex: 1 1 auto;
`;

const IconWrapper = styled.span<{
  $color?: string;
}>`
  background-color: ${({ $color, theme }) =>
    rgba($color ?? theme.iconColor, 0.1)};

  width: 32px;
  height: 32px;

  margin-right: 8px;
  padding: 6px;

  border-radius: 20px;

  .${Classes.ICON} {
    svg {
      width: 20px;
      height: 20px;
      color: ${({ $color }) => $color};
    }
  }
`;

const Total = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  margin: 10px 0 0 4px;
`;

const HelpText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  color: ${({ theme }) => theme.fontColor.MUTED};
  margin-top: 8px;
  margin-left: 8px;
`;
